import { Backdrop, Box, Fade, Modal, Typography } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  borderRadius: 2,
  bgcolor: "background.paper",
  boxShadow: 24,
  outline: "none",
  p: 2,
};

function CustomDialog(props) {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={props.open}>
        <Box sx={props.style ? props.style : style}>
          <div className="dialogy-header">
            <Typography id="transition-modal-title" variant="h6" component="h2">
              {props.title}
            </Typography>
            {props.action}
          </div>
          {props.body}
        </Box>
      </Fade>
    </Modal>
  );
}

export default CustomDialog;
