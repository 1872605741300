import { useNavigate } from "react-router-dom";
import Logo from "../../assets/img/logo.png";
import primaryColor from "../../theme/colors";
import "./style.css";
import axios from "axios";
import { api } from "../../utils/api";
import { useState } from "react";
import ToastMsg from "../../compounds/toast";
import CustomButton from "../../compounds/button";
import InputBox from "../../compounds/input";
import CryptoJS from "crypto-js";


function LoginAuth() {
    const navigate = useNavigate();
    const [toastShow, setToastShow] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastType, setToastType] = useState("");

    const openToast = (message, type) => {
        setToastMessage(message);
        setToastType(type);
        setToastShow(true);
        setTimeout(() => {
            closeToast();
        }, 5000);
    };

    const closeToast = () => {
        setToastShow(false);
    };

    const handleLogin = (event) => {

        if (event.target.elements.userEmail.value === "" || event.target.elements.userPassword.value === "") {
            openToast("All Fields are mandatory", "error");
            event.preventDefault();
            return
        }

        axios({
            url: api + "/auth/login",
            method: "POST",
            data: {
                username: event.target.elements.userEmail.value,
                password: event.target.elements.userPassword.value,
            },
        })
            .then(async (res) => {
                if (res.data.success) {
                    localStorage.setItem("PS", res.data.auth_token);
                    localStorage.setItem("PSSS", CryptoJS.AES.encrypt(res.data.user.id + "--" + res.data.user.name, '!@#$%^&*()').toString())

                    navigate("/")
                } else {
                    openToast(res.data.error, "error");
                }
            })
            .catch((err) => {
                console.log(err);
                openToast(err.response.data.error,"error")
            });
        event.preventDefault();
    };

    return (
        <div
            style={{
                backgroundColor: "rgb(238, 242, 246)",
                height: "100vh",
                width: "100vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "fixed",
            }}
        >
            <div
                className="login-form"
                style={{
                    borderRadius: 10,
                    boxShadow: "1px solid rgba(144, 202, 249, 0.145)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    height: "max-content",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        gap: 10,
                        alignItems: "center",
                    }}
                >
                    <img width={30} src={Logo} alt="logo" />
                    <h3 style={{ fontSize: 20, fontWeight: 500 }}>PS Portal</h3>
                </div>

                <h2
                    style={{
                        color: primaryColor,
                        fontWeight: 500,
                        fontSize: 23,
                        marginTop: 50,
                    }}
                >
                    Hi, Welcome Back
                </h2>


                <form
                    onSubmit={(e) => handleLogin(e)}
                    style={{
                        width: "100%",
                    }}
                >
                    <InputBox
                        name="userEmail"
                        type="username"
                        label={"Username"}
                        placeholder="Your Username"
                        margin={15}
                    />
                    <InputBox
                        name="userPassword"
                        type="password"
                        label={"Password"}
                        placeholder="Your Password"
                        margin={15}
                    />

                    <div
                        style={{
                            width: "100%",
                            marginTop: 15,
                            display: "flex",
                            justifyContent: "end",
                        }}
                    >

                    </div>
                    <CustomButton label={"Sign In"} margin={20} />
                </form>
            </div>

            {toastShow ? (
                <ToastMsg message={toastMessage} type={toastType} close={closeToast} />
            ) : null}
        </div>
    );
}

export default LoginAuth;
