import { CheckRounded, CloseRounded } from "@mui/icons-material";

function ToastMsg(props) {
  return (
    <div
      style={{
        backgroundColor:
          props.type === "success" ? "#007900f2" : "rgb(255 0 0 / 95%)",
      }}
      className={`toast-msg`}
    >
      <div style={{display:'flex',alignItems:'start',gap:10}}>
      {/* {props.type === "success" ? <CheckRounded /> : <CloseRounded/>} */}
        
        <div>
          <h4 style={{ fontWeight: 500, letterSpacing: 0.3, fontSize: 16 }}>
            {props.type === "success" ? "Success" : "Error"}
          </h4>
          <p style={{ fontSize: 14, marginTop: 5 }}>{props.message}</p>
        </div>
      </div>
      <div style={{ marginLeft: 20 }}>
        <CloseRounded
          style={{ cursor: "pointer" }}
          fontSize="sm"
          onClick={props.close}
        />
      </div>
    </div>
  );
}

export default ToastMsg;
