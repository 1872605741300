import "./style.css";

function BasicCard(props) {
  const color = props.color;
  return (
    <div
      style={{
        maxWidth: props.maxWidth ? props.maxWidth : null,
        minWidth:props.minWidth,
        overflow: props.overflow === "none" ? "" : "hidden",
        marginTop: props.margin,
        padding: props.padding,
        boxShadow: color === "white" ? "2px 1px 20px #d6d6d680" : "",
        backgroundColor:
          color === "white"
            ? "white"
            : color === "success"
            ? "#DCFCE7"
            : color === "warning"
            ? "#FFF4DE"
            : color === "danger"
            ? "#FFE2E5"
            : "",
      }}
      className={"primary-card"}
    >
      {props.body}
    </div>
  );
}

export default BasicCard;
