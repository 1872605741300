import { useNavigate } from "react-router-dom";
import CustomButton from "../../../compounds/button";
import BasicCard from "../../../compounds/cards";
import InputBox from "../../../compounds/input";
import "./style.css";
import { useState } from "react";
import ErrorText from "../../../compounds/error";
import { api } from "../../../utils/api";
import axios from "axios";

function FileCompound(props) {
  const [file, setFile] = useState(null);
  const [errorText, setErrorText] = useState("");
  const [isSubmitting, setSubmitting] = useState(false);

  const navigate = useNavigate();
  const handleSubmitFile = async (event) => {
    try {
      event.preventDefault();

      if (!file) {
        setErrorText("Please select a file to upload");
        return;
      }

      if (file.type !== "application/pdf") {
        setErrorText("Please select only PDF file");
        return;
      }

      const formData = new FormData();
      formData.append("task_file", file);
      formData.append("register_id", props.registerId);
      formData.append("task_log_id", props.question[props.selected].task_log_id);
      //   formData.append("pre_request", selectedVerticals);

      axios
        .post(
          api + "/assessment/answer/file",
          //   "http://localhost:8080/api/ps" + "/assessment/answer/file",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: localStorage.getItem("PS"),
            },
          }
        )
        .then((res) => {
          console.log(res)
          if (res.data.success) {
            props.onChange(props.selected, res.data.data);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            navigate("/auth/login");
          }
        });

      //   console.log(res);
    } catch (error) {}

    setSubmitting(false);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  return (
    <BasicCard
      padding={20}
      color="white"
      body={
        <>
          <h3>Upload your file Max (1.5 mb)</h3>
          <form onSubmit={handleSubmitFile}>
            <InputBox
              onChange={handleFileChange}
              label="Submit your file"
              margin={20}
              type="file"
            />

            <h4
              style={{
                fontWeight: 500,
                color: "green",
                marginTop: 5,
                marginLeft: 5,
                fontSize: 15,
              }}
            >
              {props.question[props.selected].response_content !== "" &&
              props.question[props.selected].response_content !== null
                ? props.question[props.selected].response_content + " Submitted"
                : null}
            </h4>

            <ErrorText label={errorText} />

            {
              // !isSubmitting
              // ?
              <CustomButton label="Submit" margin={30} />
              // : <center style={{marginTop:30}}><CircularProgress/></center>
            }
          </form>
        </>
      }
    />
  );
}

export default FileCompound;
