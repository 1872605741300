function TestCaseTable(props) {
  return (
    <table className="testcase-table" style={{ width: "100%", marginTop: 20 }}>
      <tr style={{ backgroundColor: "rgb(238, 242, 246)" }}>
        <th
          style={{ padding: 7, textAlign: "start" }}
          colSpan={props.score ? 3 : 2}
        >
          TestCase #{props.index + 1}
        </th>
      </tr>
      <tr>
        <td style={{ padding: 5 }}>Input</td>
        <td>Output</td>
        {props.score ? <td>Score</td> : null}
      </tr>
      <tr>
        <td
          style={{ padding: 5, maxWidth: 400 }}
          dangerouslySetInnerHTML={{
            __html: props.input,
          }}
        ></td>
        <td
          dangerouslySetInnerHTML={{
            __html: props.output,
          }}
        ></td>
        {props.score ? <td>{props.score}</td> : null}
      </tr>
    </table>
  );
}

export default TestCaseTable;
