import { useNavigate } from 'react-router-dom'
import CryptoJS from "crypto-js";
import './style.css'
import CustomButton from '../../compounds/button';
import { getRequest } from '../../function/axios';
import { useEffect, useState } from 'react';

function AssessmentSession() {
    const navigate = useNavigate()
    const [assessment, setAssessments] = useState([])

    const fetchAssessments = async () => {
        const res = await getRequest(
            `/assessment`,
            navigator
        );
        if (res.data.data !== null && res.data.data !== undefined) {
            setAssessments(res.data.data);
        } else {
            navigator("/404");
        }
    }

    useEffect(()=>{
        fetchAssessments()
    },[])

    return <div
        className='assessment-session'
    >
        <div className='assessment-dialog'>
            <h3 style={{ fontWeight: 500 }}>Welcome,</h3>
            <br />
            {/* <h2>Active Assessment Sessions</h2> */}

            {
                assessment.map((item, i) => (
                    <div
                        onClick={() => {
                            localStorage.setItem("tab-count", "0");
                            navigate("/assessment/" + btoa(item.id))
                        }}
                        className='assessment-card'>
                        <h3>{item.name}</h3>
                        <p>{item.description}</p>

                        <h4>Duration {item.duration}</h4>
                    </div>
                ))
            }

            {
                assessment.length===0 && <h2 className='assessment-card' style={{fontSize:18}} >No Active Assessment Found</h2>
            }

            <CustomButton func={() => navigate("/auth/logout")} danger={true} margin={20} label="Logout" />
        </div>
    </div>
}

export default AssessmentSession