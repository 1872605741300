import primaryColor from "../theme/colors";

function CustomButton(props) {
  return (
    <button
      onClick={props.func}
      style={{
        width: "100%",
        padding: props.padding !== undefined ? props.padding : 13,
        backgroundColor: props.danger ? "red" : primaryColor,
        outline: "none",
        border: "none",
        color: "white",
        flex: props.flex ? 3 : null,
        fontSize: 14,
        marginTop: props.margin,
        borderRadius: 5,
        height: "max-content",
        cursor: "pointer",
      }}
    >
      {props.label}
    </button>
  );
}

export default CustomButton;
