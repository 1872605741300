import "./style.css";

function InputBox(props) {
  return (
    <div
      style={{
        width: !props.flex ? "100%" : null,
        flex: props.flex ? 3 : null,
        marginTop: props.margin,
        // marginLeft:-12
      }}
    >
      {props.label ? (
        <label
          htmlFor={props.name}
          style={{
            color: "grey",
            fontSize: 13,
          }}
        >
          {props.label}
        </label>
      ) : null}
      <br />
      <input
        defaultValue={props.defaultValue}
        value={props.value !== undefined ? props.value : null}
        onChange={props.onChange !== undefined ? props.onChange : null}
        className="custom-input"
        style={{
          width: "100%",
          padding: 12,
          paddingLeft: 5,
          fontSize: 15,
          border: "0.5px solid grey",
          borderRadius: 5,
          marginTop: 5,
          boxSizing: "border-box",
        }}
        type={props.type}
        placeholder={props.placeholder}
        name={props.name}
        accept=".pdf"
        id={props.name}
      />
    </div>
  );
}

export default InputBox;
