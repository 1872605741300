import { CircularProgress } from "@mui/material"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

function AuthLogout(){
    const navigate = useNavigate()
    useEffect(()=>{
        localStorage.clear()
        navigate("/auth/login")
    })
    return(
        <div style={{
            height:"100vh",
            width:"100%",
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
            justifyContent:"center",
            backgroundColor:"rgb(248 248 248)"
        }}> 
            <CircularProgress size={60} />
            <br/>
            <br/>
            <h2>Logging Out...</h2>
        </div>
    )
}

export default AuthLogout