import { useEffect, useState } from "react";

function AssessmentTopBar(props) {
  // Parse time string (e.g., "1:00:00") to get hours, minutes, seconds
  const parseTimeString = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return { hours, minutes, seconds };
  };

  // Calculate time remaining
  const calculateTimeRemaining = (timeString) => {
    const now = new Date();
    const { hours, minutes, seconds } = parseTimeString(timeString);
    const endTime = new Date(now);
    endTime.setHours(now.getHours() + hours);
    endTime.setMinutes(now.getMinutes() + minutes);
    endTime.setSeconds(now.getSeconds() + seconds);

    const difference = endTime - now;
    const secondsRemaining = Math.max(0, Math.floor(difference / 1000));

    return secondsRemaining;
  };

  // Default end time is "1:00:00"
  const defaultEndTime = "1:00:00";
  const [remainingTime, setRemainingTime] = useState(calculateTimeRemaining(defaultEndTime));

  useEffect(() => {
    const timer = setTimeout(() => {
      setRemainingTime((prevTime) => {
        if (prevTime > 0) {
          return prevTime - 1;
        } else {
          props.handleFinishTest();
          return 0;
        }
      });
    }, 1000);

    return () => clearTimeout(timer);
  }, [remainingTime]);

  const formatTimeUnit = (value) => (value < 10 ? `0${value}` : value);

  const formattedTime = {
    hours: Math.floor(remainingTime / 3600),
    minutes: Math.floor((remainingTime % 3600) / 60),
    seconds: remainingTime % 60,
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        display: "flex",
        padding: 15,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div className="assessment-header">
        <h3> {props.title} </h3>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 20,
        }}
      >
        <h4 style={{ fontWeight: 500 }}>
          Ends In: {formatTimeUnit(formattedTime.hours)}:{formatTimeUnit(formattedTime.minutes)}:{formatTimeUnit(formattedTime.seconds)}
        </h4>
        <button
          onClick={props.handleOpen}
          style={{
            backgroundColor: "red",
            padding: "7px 15px",
            outline: "none",
            border: "none",
            color: "white",
            fontSize: 16,
            borderRadius: 8,
            cursor: "pointer",
          }}
        >
          Finish Now
        </button>
      </div>
    </div>
  );
}

export default AssessmentTopBar;
