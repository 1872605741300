import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import AssessmentSession from './pages/session';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import Assessment from './pages/assessment';
import LoginAuth from './pages/auth/login';
import AuthLogout from './pages/auth/logout';

const root = ReactDOM.createRoot(document.getElementById('root'));

function Protected({ children }) {
  const navigate = useNavigate();
  const [isAuth, setAuth] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("PS") === undefined || localStorage.getItem("PS") === "" || localStorage.getItem("PS") === null) {
      setAuth(false);
      navigate("/auth/login");
    } else {
      setAuth(true);
    }
  }, [navigate]);

  if (isAuth) {
    return children;
  }
}

root.render(
  <BrowserRouter>
    <Routes>
      <Route path='/auth/login' element={<LoginAuth />} />


      <Route path='/' element={<Protected><AssessmentSession /></Protected>} />
      <Route path='/auth/logout' element={<Protected><AuthLogout /></Protected>} />
      <Route path='/assessment/:registerId' element={<Protected><Assessment /></Protected>} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
